import React from "react";
import tw from "twin.macro";
import "tailwindcss/lib/css/preflight.css";
import Header from "components/headers/light";
import Footer from "components/footers/MiniCenteredFooter";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import FaqColumn from "components/faqs/SingleCol";
import ReactGA from "react-ga4";

ReactGA.send({
  hitType: "pageview",
  page: "/",
  title: "FAQ Page",
});

function FAQ() {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <FaqColumn />
      <Footer />
    </AnimationRevealPage>
  );
}

export default FAQ;
