import React from "react";
import tw from "twin.macro";
import "tailwindcss/lib/css/preflight.css";
import Header from "components/headers/light";
import Footer from "components/footers/MiniCenteredFooter";
import FullForm from "components/forms/ContactUsForm";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import ReactGA from "react-ga4";

ReactGA.send({
  hitType: "pageview",
  page: "/",
  title: "Contact Page",
});

function ContactUs() {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <FullForm />
      <Footer />
    </AnimationRevealPage>
  );
}

export default ContactUs;
