import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { useForm } from "react-hook-form";
import { postSignUp } from "helpers/discord";
import { supabase } from "helpers/supabase";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  select,
  textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5`;
const Label = tw.label`tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`;

export default () => {
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    postSignUp(data);
    setSubmitted(true);
  };
  // console.log(watch('email')); // watch input value by passing the name of it

  const joinWaitlist = async (data) => {
    // data will be an object { name: 'name', email: 'email' }
    const { error } = await supabase.from("waitlist").insert([data]);
    if (error) {
      console.log("error", error);
    } else {
      console.log("success");
    }
  };

  return (
    <Container>
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <h2>Request Early Access</h2>
            <p tw="mt-4 text-sm">
              We are currently in private beta. Tell us about yourself and we'll
              reach out when we're ready for you to try out our platform.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Your Name</Label>
                    <Input
                      id="name-input"
                      type="text"
                      name="name"
                      placeholder="e.g. John Doe"
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span tw="text-red-500">This field is required</span>
                    )}
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Your Email Address</Label>
                    <Input
                      id="email-input"
                      type="email"
                      name="email"
                      placeholder="e.g. john@mail.com"
                      {...register("email", { required: true })}
                    />
                    {errors.email && (
                      <span tw="text-red-500">This field is required</span>
                    )}
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="linkedin-input">
                      Your LinkedIn Profile
                    </Label>
                    <Input
                      id="linkedin-input"
                      type="url"
                      name="url"
                      placeholder="e.g. https://www.linkedin.com/in/johndoe"
                      {...register("linkedin", { required: true })}
                    />
                    {errors.linkedin && (
                      <span tw="text-red-500">This field is required</span>
                    )}
                  </InputContainer>
                  {/* <InputContainer>
                    <Label htmlFor="github-input">
                      Your GitHub Profile (optional)
                    </Label>
                    <Input
                      id="github-input"
                      type="url"
                      name="url"
                      placeholder="e.g. https://www.github.com/johndoe"
                      {...register('github')}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="website-input">
                      Your Personal Website or Portfolio (optional)
                    </Label>
                    <Input
                      id="website-input"
                      type="url"
                      name="url"
                      placeholder="e.g. https://www.johndoe.com"
                      {...register('website')}
                    />
                  </InputContainer> */}
                </Column>
                <Column>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="expertise-select">
                      What best describes your area of expertise?
                    </Label>
                    <select {...register("expertise", { required: true })}>
                      <option value="" disabled selected>
                        Please select
                      </option>
                      <option value="Business">Business</option>
                      <option value="Design">Design</option>
                      <option value="Engineering">Engineering</option>
                      <option value="Marketing">Marketing</option>
                      <option value="Operations">Operations</option>
                      <option value="Product">Product</option>
                    </select>
                    {errors.expertise && (
                      <span tw="text-red-500">This field is required</span>
                    )}
                  </InputContainer>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="looking-select">
                      What are you looking to do on CoFind?
                    </Label>
                    <select {...register("looking", { required: true })}>
                      <option value="" disabled selected>
                        Please select
                      </option>
                      <option value="Network">Network and make friends</option>
                      <option value="Contract">Find contractor work</option>
                      <option value="Cofounder">Find a co-founder</option>
                    </select>
                    {errors.looking && (
                      <span tw="text-red-500">This field is required</span>
                    )}
                  </InputContainer>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="expertise-select">
                      What are your startup goals?
                    </Label>
                    <select {...register("goals", { required: true })}>
                      <option value="" disabled selected>
                        Please select
                      </option>
                      <option value="passiveIncome">Passive Income</option>
                      <option value="fulltimeStartup">
                        Transition into full-time at startup
                      </option>
                      <option value="unicorn">Build a unicorn</option>
                      <option value="dunno">Unsure or N/A </option>
                    </select>
                    {errors.goals && (
                      <span tw="text-red-500">This field is required</span>
                    )}
                  </InputContainer>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="misc-input">
                      Anything else you want to share with us? (optional)
                    </Label>
                    <TextArea
                      id="misc-input"
                      name="misc"
                      placeholder=""
                      {...register("misc")}
                    />
                  </InputContainer>
                  {/* <InputContainer tw="flex-1">
                    <Label htmlFor="name-input">Your Education</Label>
                    <TextArea
                      id="education-input"
                      name="education"
                      placeholder="e.g. Details about your education"
                      {...register('education', { required: true })}
                    />
                    {errors.education && (
                      <span tw="text-red-500">This field is required</span>
                    )}
                  </InputContainer>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="name-input">Your Work Experience</Label>
                    <TextArea
                      id="experience-input"
                      name="experience"
                      placeholder="e.g. Details about your work experience"
                      {...register('experience', { required: true })}
                    />
                    {errors.experience && (
                      <span tw="text-red-500">This field is required</span>
                    )}
                  </InputContainer> */}
                  <SubmitButton type="submit" value="Submit">
                    Submit
                  </SubmitButton>
                  {submitted && (
                    <p tw="mt-2 text-lg">Message sent! We'll be in touch</p>
                  )}
                </Column>
              </TwoColumn>
            </form>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};
