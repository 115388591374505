import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as UsersIcon } from "feather-icons/dist/icons/users.svg";
import { ReactComponent as UserPlusIcon } from "feather-icons/dist/icons/user-plus.svg";
import { ReactComponent as ChatIcon } from "feather-icons/dist/icons/message-circle.svg";
import { ReactComponent as SearchIcon } from "feather-icons/dist/icons/search.svg";
import { ReactComponent as ToolIcon } from "feather-icons/dist/icons/tool.svg";
import { ReactComponent as ShieldIcon } from "feather-icons/dist/icons/shield.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import TeamIllustrationSrc from "images/team-illustration-3.png";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mt-8 max-w-lg mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-500`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-primary-500`;
const FeatureDescription = tw.div`mt-1 text-sm`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

export default ({
  subheading = "Why CoFind?",
  heading = (
    <>
      How we're <span tw="text-primary-500">different</span>
    </>
  ),
  description = "We vet members so that our community is filled with cool and talented people – students and graduates from among the top MBA, computer science, and product design programs and members with diverse industry experience.",
  primaryButtonText = "See Our Portfolio",
  primaryButtonUrl = "https://timerse.com",
  features = null,
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    {
      Icon: ChatIcon,
      title: (
        <>
          We have <span tw="text-primary-800">quality</span> and{" "}
          <span tw="text-primary-800">diversity</span>
        </>
      ),
      description:
        "Many schools have existing entrepreneurial communities, but isn’t the network even more powerful if we make connections across different schools and backgrounds? Chat with people from different backgrounds and find co-founders with complementary skills.",
    },
    {
      Icon: ToolIcon,
      title: (
        <>
          We have <span tw="text-primary-800">cool bot features</span>
        </>
      ),
      description:
        "Our bot features make your life easier. No more wondering who @rockstar99 is – simply right click and pull up their profile. Looking for a co-founder with particular skills or experience? We have the filters ready for you (coming soon).",
    },
    {
      Icon: ShieldIcon,
      title: (
        <>
          We have <span tw="text-primary-800">privacy</span>
        </>
      ),
      description:
        "Your name, LinkedIn, and email will be hidden from all users. Only verified users will be able to view the rest of your profile.",
    },
    {
      Icon: StarIcon,
      title: (
        <>
          We're <span tw="text-primary-800">free</span>
        </>
      ),
      description:
        "None of our members are more premium just because they pay. All of our features will be available to all verified members.",
    },
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={TeamIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            {/* <Description>{description}</Description> */}
            <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureIconContainer>
                    {<feature.Icon />}
                  </FeatureIconContainer>
                  <FeatureText>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                    <FeatureDescription>
                      {feature.description}
                    </FeatureDescription>
                  </FeatureText>
                </Feature>
              ))}
            </Features>
            {/* <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
