import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { useForm } from "react-hook-form";
import { supabase } from "helpers/supabase";
import { postWaitlist } from "helpers/discord";

const Container = tw.div`text-black`;
const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500 font-black`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

export default ({ page, loc }) => {
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    joinWaitlist(data);
    postWaitlist(data);
    setSubmitted(true);
  };
  console.log(watch("email")); // watch input value by passing the name of it

  const joinWaitlist = async (data) => {
    // data will be an object { name: 'name', email: 'email' }
    const { error } = await supabase.from("waitlist").insert([data]);
    if (error) {
      console.log("error", error);
    } else {
      console.log("success");
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Actions>
          <input
            type="email"
            placeholder="Your Email Address"
            {...register("email", { required: true })}
          />
          <button type="submit">
            {submitted ? "Joined!" : "Join Waitlist"}
          </button>
        </Actions>
      </form>
    </Container>
  );
};
