import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { useForm } from "react-hook-form";
import { supabase } from "helpers/supabase";
import { postClickedSignup } from "helpers/discord";

const Container = tw.div`text-black`;
const Button = styled.button`
  ${tw`w-full bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded py-4 sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
`;

export default ({ placement }) => {
  const { handleSubmit } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    postClickedSignup(window.location.pathname, placement);
    window.location.href = "https://discord.gg/CkmTWJnFcN";
  };

  return (
    <Button type="submit" onClick={handleSubmit(onSubmit)}>
      Join Discord
    </Button>
  );
};
