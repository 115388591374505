import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Laptop from "../../images/laptop.png";
import bg from "../../images/bg-3.jpg";
import Typewriter from "typewriter-effect";
import WaitlistInput from "components/WaitlistInput";
import SignUpButton from "components/SignUpButton";

import Header from "../headers/light.js";

const Container = styled.div`
  background-image: url(${bg});
  ${tw`relative -mx-8 -mt-8 h-2/3 bg-center`}
`;
const TwoColumn = tw.div`flex flex-col lg:flex-row py-20 md:py-24`;
const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8`;
// background-image: url(${Laptop});
// ${tw`bg-contain bg-right bg-no-repeat xl:ml-24 h-96 lg:h-auto lg:w-1/2 lg:flex-1`}
const RightColumn = styled.div`
  ${tw`lg:w-1/2 lg:flex-1`}
  img {
    ${tw`mt-4 ml-auto w-full max-w-5xl lg:pl-16 xl:pl-32`}
  }
`;

const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-3xl sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl font-black leading-none mb-8`;
const Paragraph = tw.p`max-w-xl mb-8 lg:mb-5 lg:mb-8 sm:text-lg lg:text-base xl:text-lg leading-loose`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-primary-500 text-gray-100 hover:bg-primary-700`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
  }
`;

export default ({
  // navLinks = [
  //   <NavLinks key={1}>
  //     <NavLink href="#">About</NavLink>
  //     <NavLink href="#">Blog</NavLink>
  //     <NavLink href="#">Pricing</NavLink>
  //     <NavLink href="#">Login</NavLink>
  //   </NavLinks>,
  // ],
  heading = (
    <>
      Find _____________
      <wbr />
      <br />
      <span tw="text-primary-500">anywhere you go.</span>
    </>
  ),
  description = "",
  primaryActionUrl = "#",
  primaryActionText = "Sign Up",
  secondaryActionUrl = "#",
  secondaryActionText = "Search Hotels",
}) => {
  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
          <Header />
          <Content>
            <Heading>
              CoFind{" "}
              <Typewriter
                tw="inline-block"
                options={{
                  strings: ["your cofounder", "your community", "your people"],
                  autoStart: true,
                  loop: true,
                }}
              />
            </Heading>
            <Paragraph>
              <h3 tw="text-xl font-semibold">
                We're the Discord community for your tech startup journey.
              </h3>{" "}
              <br />
              Think of us as your school’s entrepreneurship program, but
              multiplied across other top business and technical programs. Find
              co-founders, get feedback from industry experts, make connections
              and advance your startup career.
            </Paragraph>
            <Paragraph tw="font-medium leading-relaxed text-secondary-600">
              Let’s build together. Join our vetted and verified members from
              UPenn, Stanford, Columbia, Cornell, UIUC and more.
            </Paragraph>
            <SignUpButton placement="hero" />
          </Content>
        </LeftColumn>
        <RightColumn>
          <img src={Laptop} />
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};
