import React from "react";
import ReactDOM from "react-dom/client";
import "style.css";
import App from "./App";
import SignUp from "./components/SignUp";
import ContactUs from "./components/ContactUs";
import FAQ from "./components/FAQ";
import AboutUs from "components/AboutUs";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactGA from "react-ga4";
ReactGA.initialize("G-S91D99M1N8");

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "about",
    element: <AboutUs />,
  },
  {
    path: "contact",
    element: <ContactUs />,
  },
  {
    path: "faq",
    element: <FAQ />,
  },
  {
    path: "signup",
    element: <SignUp />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
