export const postContactUs = async (data) => {
  const { name, email, subject = "n/a", message } = data;
  const postData = {
    embeds: [
      {
        color: 14177041,
        title: "Contact Us",
        fields: [
          {
            name: "Name",
            value: name,
            inline: true,
          },
          {
            name: "Email",
            value: email,
            inline: true,
          },
          {
            name: "Subject",
            value: subject,
            inline: true,
          },
          {
            name: "Message",
            value: message,
            inline: true,
          },
        ],
        timestamp: new Date().toISOString(),
      },
    ],
  };

  const reqObj = {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(postData),
  };
  const myRequest = new Request(
    process.env.REACT_APP_DC_WEBHOOK_CONTACT,
    reqObj
  );
  return fetch(myRequest).then((response) => {
    console.log(response);
    if (response.ok) {
      return true;
    }
  });
};

export const postSignUp = async (data) => {
  const {
    name,
    email,
    misc = "n/a",
    expertise,
    goals,
    linkedin,
    looking,
  } = data;
  const postData = {
    embeds: [
      {
        color: 14177041,
        title: "Sign Up",
        fields: [
          {
            name: "Name",
            value: name,
            inline: true,
          },
          {
            name: "Email",
            value: email,
            inline: true,
          },
          {
            name: "LinkedIn",
            value: linkedin,
            inline: true,
          },
          {
            name: "Expertise",
            value: expertise,
            inline: true,
          },
          {
            name: "Goals",
            value: goals,
            inline: true,
          },
          {
            name: "Looking For",
            value: looking,
            inline: true,
          },
          {
            name: "Misc",
            value: misc,
            inline: true,
          },
        ],
        timestamp: new Date().toISOString(),
      },
    ],
  };

  const reqObj = {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(postData),
  };
  const myRequest = new Request(
    process.env.REACT_APP_DC_WEBHOOK_SIGNUP,
    reqObj
  );
  return fetch(myRequest).then((response) => {
    console.log(response);
    if (response.ok) {
      return true;
    }
  });
};

export const postWaitlist = async (data) => {
  const { email } = data;
  const postData = {
    embeds: [
      {
        color: 5763719,
        title: "Waitlist",
        fields: [
          {
            name: "Email",
            value: email,
            inline: true,
          },
        ],
        timestamp: new Date().toISOString(),
      },
    ],
  };

  const reqObj = {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(postData),
  };
  const myRequest = new Request(
    process.env.REACT_APP_DC_WEBHOOK_SIGNUP,
    reqObj
  );
  return fetch(myRequest).then((response) => {
    console.log(response);
    if (response.ok) {
      return true;
    }
  });
};

export const postClickedSignup = async (path, placement) => {
  const postData = {
    embeds: [
      {
        color: 5763719,
        title: `Join Discord button clicked`,
        fields: [
          {
            name: "Placement",
            value: placement,
            inline: true,
          },
          {
            name: "Path",
            value: path,
            inline: true,
          },
        ],
        timestamp: new Date().toISOString(),
      },
    ],
  };

  const reqObj = {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(postData),
  };
  const myRequest = new Request(
    process.env.REACT_APP_DC_WEBHOOK_SIGNUP,
    reqObj
  );
  return fetch(myRequest).then((response) => {
    if (response.ok) {
      return true;
    }
  });
};

const requestBuilder = (data, type) => {
  const { name, email, message } = data;
  const postData = {
    embeds: [
      {
        color: 14177041,
        title: "Contact Us",
        description: "Question",
        fields: [
          {
            name: "Name",
            value: name,
            inline: true,
          },
          {
            name: "Email",
            value: email,
            inline: true,
          },
          {
            name: "Message",
            value: message,
          },
        ],
      },
    ],
  };
  const headers = {
    "Content-Type": "application/json",
    method: "POST",
    body: postData,
  };
};
