import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { Link as RouterLink } from "react-router-dom";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;
const BodyDescription = tw.p`mt-4 font-medium text-gray-600 max-w-2xl`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1550699026-4114bbf4fb49?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=632&q=80",
      subtitle: "Paid",
      title: "Loachella, NYC",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      url: "https://timerse.com",
    },

    {
      imageSrc:
        "https://images.unsplash.com/photo-1543423924-b9f161af87e4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
      subtitle: "Free",
      title: "Rock In Rio, Upstate",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      url: "https://timerse.com",
    },

    {
      imageSrc:
        "https://images.unsplash.com/photo-1509824227185-9c5a01ceba0d?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
      subtitle: "Exclusive",
      title: "Lollapalooza, Manhattan",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      url: "https://timerse.com",
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>About Us</HeadingTitle>
          <HeadingDescription>Our Origin Story</HeadingDescription>
          <BodyDescription>
            Hi! We’re Jen and Jamie. I (Jen) studied Psychology and Fashion
            Design in college, and I’m about to graduate from my Wharton MBA. In
            2021, I met Jamie, a software engineer with over ten years of
            experience, and we decided to build CoFind together.
          </BodyDescription>
          <BodyDescription>
            Entrepreneurs never have enough hours in the day &mdash; but if you
            have a co-founder or team, you gain more hours and knowledge. CoFind
            is designed to help you find the ideal co-founder: someone with
            complementary skills so that you can each focus on what you’re best
            at.
          </BodyDescription>
          {/* <BodyDescription>
            <i>
              We want to help you connect with the right people, at the right
              time.
            </i>
          </BodyDescription>
          <BodyDescription>
            We’ve carefully designed our filters to help you find who you’re
            looking for. We also vet all users so that you can be confident that
            any profiles you see will be of high quality.
          </BodyDescription>
          <BodyDescription>
            <i>We want to make this more personal.</i>
          </BodyDescription>
          <BodyDescription>
            It’s hard to start a conversation when the entire profile is all
            work and no play. Find out what you have in common as people.
            Afterall, cofounders need to vibe personally and professionally.
          </BodyDescription>
          <BodyDescription>
            <i>We want to create opportunities to work together.</i>
          </BodyDescription>
          <BodyDescription>
            The only way to know if you would work well together is…to work
            together. CoFind will keep an updated list on the latest pitch
            competitions and hackathons so that you can find team members who
            might become your next cofounder.
          </BodyDescription> */}
          <BodyDescription>
            A successful startup has many components &mdash; the idea, the
            business model, the funds, the market, etc.{" "}
            <a
              href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=1505846"
              target="_blank"
              rel="noreferrer"
            >
              But venture capitalists attribute 65% of startup failure to the
              management team
            </a>
            . The idea and market are subject to change during a startup’s life,
            but the team is the unchanging core of the startup.
          </BodyDescription>
          <BodyDescription>
            We hope that you can meet meaningful connections on CoFind &mdash;
            whether it’s a friend who also doubles as a sounding board, an
            expert in an industry that interests you, or a co-founder you can
            build a company with.
          </BodyDescription>
          <BodyDescription>
            Thank you for checking out our platform and please{" "}
            <RouterLink to="/contact">contact us</RouterLink> if you have any
            questions or suggestions!
          </BodyDescription>
        </HeadingInfoContainer>

        {/* <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link href={card.url}>See Event Details</Link>
              </Details>
            </Card>
          ))}
        </Content> */}
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
