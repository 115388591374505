import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Hero from "components/hero/TwoColumnWithInput";
import CoFindHero from "components/hero/CoFindHero";
import Features from "components/features/TwoColWithButton";
import CoFindFeatures from "components/features/CoFindFeatures";
import Steps from "components/features/TwoColWithSteps";
import Footer from "components/footers/MiniCenteredFooter";
import GetStarted from "components/cta/GetStarted";
import ReactGA from "react-ga4";

ReactGA.send({
  hitType: "pageview",
  page: "/",
  title: "Home Page",
});

function App() {
  return (
    <AnimationRevealPage>
      <CoFindHero />
      <Steps />
      <CoFindFeatures />
      {/* <Features /> */}
      <Footer />
    </AnimationRevealPage>
  );
}

export default App;
